import {Grid} from '@mui/material';
import {gridSpacing} from '../../../../store/constant';
import {useLocation} from 'react-router-dom';
import TabContext from '@mui/lab/TabContext';
import Top from './Top';
import Content from './Content';

export default function Main() {

    const location = useLocation();

    return (
        <TabContext value={location.pathname}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                sx={{
                    height: '100%',
                }}
            >
                <Grid item>
                    <Grid container spacing={gridSpacing} alignItems="stretch">
                        <Grid item xs={12}>
                            <Top/>
                        </Grid>
                        <Grid item xs={12} sx={{flex: '1 1 auto'}}>
                            <Content/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TabContext>
    );
}