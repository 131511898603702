import {Typography, useTheme} from '@mui/material';
import {useMemo, useState} from 'react';
// import ReadLess from './Ending/ReadLess';
// import ReadMore from './Ending/ReadMore';
import Empty from './Empty';
import previewText from '../../../../../../utils/previewText';
import Ending from './Ending';

export default function AboutMe({aboutMe}) {

    const [aboutOpen, setAboutOpen] = useState(false);
    const text = useMemo(() => aboutOpen ? aboutMe : previewText(aboutMe, {ending: ''}), [aboutMe, aboutOpen]);

    const onTextClick = e => {

        e.preventDefault();

        if (!aboutOpen) {

            setAboutOpen(true);
        }
    }

    return (
        <>
            <Typography variant="caption" component="div">О себе</Typography>
            {
                !!aboutMe &&
                <Typography variant="body1" component="div" onClick={onTextClick} sx={{cursor: aboutOpen ? 'default' : 'pointer'}}>
                    {text} <Ending open={aboutOpen} onClick={() => setAboutOpen(aboutOpen => !aboutOpen)}/>
                </Typography>
            }
            {
                !aboutMe &&
                <Empty/>
            }
        </>
    );
}