import {useState} from 'react';
import MuiMenu from '@mui/material/Menu';
import usePostStatusData from '../../../hooks/usePostStatusData';
import {
    POST_DRAFT_STATUS,
    POST_PUBLISH_VERIFICATION_STATUS,
    POST_SCHEDULE_VERIFICATION_STATUS,
    POST_SCHEDULED_STATUS,
    POST_PUBLISHED_STATUS,
    POST_BLOCKED_STATUS,
} from '../../../../../../store/slices/lawyerMedia';
import MenuItem from './MenuItem';

export default function Menu1({anchorEl, handleClose, disableStatuses = [], postId, scheduledDate = null, status}) {

    const [_loading, _setLoading] = useState(false);
    const postDraftStatusData = usePostStatusData(POST_DRAFT_STATUS);
    const postPublishVerificationStatusData = usePostStatusData(POST_PUBLISH_VERIFICATION_STATUS);
    const postScheduleVerificationStatusData = usePostStatusData(POST_SCHEDULE_VERIFICATION_STATUS);
    const postPublishedStatusData = usePostStatusData(POST_PUBLISHED_STATUS);
    const postScheduledStatusData = usePostStatusData(POST_SCHEDULED_STATUS);
    const postBlockedStatusData = usePostStatusData(POST_BLOCKED_STATUS);
    console.log({status});
    return (
        <MuiMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {/*<MenuItem*/}
            {/*    onClick={handleClose}*/}
            {/*    disabled={disableStatuses.includes(postDraftStatusData.status) || _loading}*/}
            {/*    selected={disableStatuses.includes(postDraftStatusData.status) || _loading}*/}
            {/*    text={postDraftStatusData.text}*/}
            {/*    color={postDraftStatusData.color}*/}
            {/*    request={postDraftStatusData.request}*/}
            {/*    setLoading={_setLoading}*/}
            {/*    postId={postId}*/}
            {/*    scheduledDate={scheduledDate}*/}
            {/*/>*/}
            {
                (!scheduledDate) &&
                <MenuItem
                    onClick={handleClose}
                    disabled={disableStatuses.includes(postPublishVerificationStatusData.status) || _loading}
                    selected={disableStatuses.includes(postPublishVerificationStatusData.status) || _loading}
                    text={postPublishVerificationStatusData.text}
                    color={postPublishVerificationStatusData.color}
                    request={postPublishVerificationStatusData.request}
                    setLoading={_setLoading}
                    postId={postId}
                    scheduledDate={scheduledDate}
                />
            }
            {
                (!scheduledDate) &&
                <MenuItem
                    onClick={handleClose}
                    disabled={disableStatuses.includes(postPublishedStatusData.status) || _loading}
                    selected={disableStatuses.includes(postPublishedStatusData.status) || _loading}
                    text={postPublishedStatusData.text}
                    color={postPublishedStatusData.color}
                    request={postPublishedStatusData.request}
                    setLoading={_setLoading}
                    postId={postId}
                    scheduledDate={scheduledDate}
                />
            }
            {
                (!!scheduledDate) &&
                <MenuItem
                    onClick={handleClose}
                    disabled={disableStatuses.includes(postScheduleVerificationStatusData.status) || _loading}
                    selected={disableStatuses.includes(postScheduleVerificationStatusData.status) || _loading}
                    text={postScheduleVerificationStatusData.text}
                    color={postScheduleVerificationStatusData.color}
                    request={postScheduleVerificationStatusData.request}
                    setLoading={_setLoading}
                    postId={postId}
                    scheduledDate={scheduledDate}
                />
            }
            {
                (!!scheduledDate) &&
                <MenuItem
                    onClick={handleClose}
                    disabled={disableStatuses.includes(postScheduledStatusData.status) || _loading}
                    selected={disableStatuses.includes(postScheduledStatusData.status) || _loading}
                    text={postScheduledStatusData.text}
                    color={postScheduledStatusData.color}
                    request={postScheduledStatusData.request}
                    setLoading={_setLoading}
                    postId={postId}
                    scheduledDate={scheduledDate}
                />
            }
            <MenuItem
                onClick={handleClose}
                disabled={disableStatuses.includes(postBlockedStatusData.status) || _loading}
                selected={disableStatuses.includes(postBlockedStatusData.status) || _loading}
                text={postBlockedStatusData.text}
                color={postBlockedStatusData.color}
                request={postBlockedStatusData.request}
                setLoading={_setLoading}
                postId={postId}
                scheduledDate={scheduledDate}
            />
        </MuiMenu>
    );
}