import {Box} from '@mui/material';

export default function ReadLess({onClick}) {

    return (
        <Box sx={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start'}}>
            <Box onClick={onClick} sx={{fontWeight: 600, cursor: 'pointer', mt: '4px'}}>
                Свернуть
            </Box>
        </Box>
    );
}