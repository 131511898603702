import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Grid} from '@mui/material';
import {fetchWriters, selectWriters, selectWritersUpdating} from '../../../../store/slices/lawyerMedia';
import EmptyText from '../../../../ui-component/EmptyText';
import {useDispatch, useSelector} from '../../../../store';
import Writer from './Writer';
import {gridSpacing} from '../../../../store/constant';

export default function Writers() {

    const dispatch = useDispatch();
    const writersUpdating = useSelector(selectWritersUpdating);
    const writers = useSelector(selectWriters);
    const [searchParams] = useSearchParams();
    const writer = searchParams.get('writer');

    useEffect(() => {

        dispatch(fetchWriters({withoutUpdating: false, lawyerMediaId: writer, replace: !!writer}));

    }, [writer]);

    return (
        <Grid
            container
            spacing={gridSpacing}
            alignItems="stretch"
            sx={{
                height: writers.length > 0 ? 'auto' : '100%',
            }}
        >
            {
                writersUpdating
                    ? (
                        <Grid xs={12} item>
                            <EmptyText text="Загрузка..."/>
                        </Grid>
                    )
                    : (
                        <>
                            {
                                writers.length > 0
                                    ? (
                                        writers.map(writer => (
                                            <Grid key={writer.id} item xs={12} md={6} xl={4}>
                                                <Writer {...writer}/>
                                            </Grid>
                                        ))
                                    )
                                    : (
                                        <Grid xs={12} item>
                                            <EmptyText text="Нет авторов" />
                                        </Grid>
                                    )
                            }
                        </>
                    )

            }
        </Grid>
    );
}