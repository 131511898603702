import previewText from '../../../../../utils/previewText';
import {useMemo} from 'react';
import stripHtml from '../../../../../utils/stripHtml';
import {Typography} from '@mui/material';

export default function Content({content}) {

    const previewContent = useMemo(() => previewText(stripHtml(content || '')), [content]);

    return (
        <Typography gutterBottom variant="body2" sx={{color: 'text.secondary'}}>
            {previewContent}
        </Typography>
    );
}