import {Box, Grid} from '@mui/material';
import {useSearchParams} from 'react-router-dom';
import Writer from './Writer';

export default function Filter() {

    const [searchParams] = useSearchParams();
    const writer = searchParams.get('writer');

    return (
        <Grid container>

            {
                writer &&
                <Grid item>
                    <Writer/>
                </Grid>
            }
        </Grid>
    );
}