import {Box, Grid, Typography, useTheme} from '@mui/material';
import {useState} from 'react';
import usePostStatusData from '../../hooks/usePostStatusData';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import {LoadingButton} from '@mui/lab';
import {
    POST_PUBLISH_VERIFICATION_STATUS,
    POST_PUBLISHED_STATUS,
    POST_SCHEDULE_VERIFICATION_STATUS, POST_SCHEDULED_STATUS
} from '../../../../../store/slices/lawyerMedia';

function getStatusForRequest(status) {

    if (status === POST_PUBLISH_VERIFICATION_STATUS) {

        return POST_PUBLISHED_STATUS;
    }

    if (status === POST_SCHEDULE_VERIFICATION_STATUS) {

        return POST_SCHEDULED_STATUS;
    }

    return null;
};

export default function PublicVerification({postId, status}) {

    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const {text} = usePostStatusData(status);
    const {request} = usePostStatusData(getStatusForRequest(status));

    const onClick = e => {

        e.preventDefault();

        setLoading(true);

        request(postId)
            .catch(error => {
                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });
    };

    return (
        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
                <Grid container spacing={1} alignItems="center" flexWrap="nowrap">
                    <Grid item>
                        <Box sx={{position: 'relative',}}>
                            <ErrorOutlineRoundedIcon  sx={{color: theme.palette.error.contrastText, display: 'block'}}/>
                        </Box>
                    </Grid>
                    <Grid item><Typography component="div" color={theme.palette.error.contrastText}>{text}</Typography></Grid>
                </Grid>
            </Grid>
            <Grid item>
                <LoadingButton
                    variant="contained"
                    color="invertText"
                    size="small"
                    disableElevation
                    onClick={onClick}
                    loading={loading}
                >
                    Опубликовать
                </LoadingButton>
            </Grid>
        </Grid>
    );
}