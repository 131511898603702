import {Typography} from '@mui/material';
import {useMemo} from 'react';
import previewText from '../../../../../utils/previewText';

export default function Title({title}) {

    const previewTitle = useMemo(() => previewText(title || ''), [title]);

    return (
        <Typography gutterBottom variant="h5" component="div">
            {previewTitle}
        </Typography>
    );
}