import {useSearchParams} from 'react-router-dom';
import {Box, Chip} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {useSelector} from '../../../../../../store';
import {selectWriters} from '../../../../../../store/slices/lawyerMedia';
import Button from '@mui/material/Button';

export default function Writer() {

    const [searchParams, setSearchParams] = useSearchParams();
    const writerId = searchParams.get('writer');
    const writers = useSelector(selectWriters);
    const {name} = writers.find(({id}) => id === writerId) || {};

    const onClick = e => {

        e.preventDefault();

        searchParams.delete('writer');

        setSearchParams(searchParams);
    };

    return (
        <Box>
            {
                name &&
                <Chip label={`Автор: ${name}`} onClick={onClick} onDelete={onClick} />
            }
        </Box>
    );
}