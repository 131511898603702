import {BASE_PATH} from '../config';

export default {
    root: '/',
    dashboard: '/',

    signIn: '/sign-in',
    signOut: '/sign-out',
    lawyers: '/lawyers',
    controversialChats: '/controversial-stages',
    controversialChatsNew: '/controversial-stages/new',

    cases: '/cases',
    case: '/cases/case',
    newCases: '/cases/new',
    caseNewCases: '/cases/new/case',
    activeCases: '/cases/active',
    caseActiveCases: '/cases/active/case',
    completedCases: '/cases/completed',
    caseCompletedCases: '/cases/completed/case',
    caseStageChat: '/cases/stage-chat',
    caseStage: '/cases/stage',
    chat: '/chat',
    anonymousChats: '/anonymous-chats',
    anonymousChat: '/anonymous-chats/chat',

    lawyerMedia: '/lawyer-media',
    lawyerMediaWriters: '/lawyer-media/writers',
    lawyerMediaWriter: '/lawyer-media/writer',
    lawyerMediaPosts: '/lawyer-media/posts',
    lawyerMediaPost: '/lawyer-media/post',

    underConstruction: '/under-construction',
};
