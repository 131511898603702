import Tabs from './Tabs';
import {Box, Grid, useTheme} from '@mui/material';
import Filter from './Filter';
import Divider from '@mui/material/Divider';
import {useSearchParams} from 'react-router-dom';

export default function Top() {

    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const writer = searchParams.get('writer');
    const showDivider = !!writer;

    return (
        <Grid container alignItems="stretch" spacing={3}>
            <Grid item>
                <Tabs/>
            </Grid>
            {
                showDivider &&
                <Grid item>
                    <Box display="flex" sx={{height: '100%'}}>
                        <Divider orientation="vertical" variant="middle" flexItem sx={{borderColor: theme.palette.grey[500]}} />
                    </Box>
                </Grid>
            }
            <Grid item>
                <Grid container alignItems="center" sx={{height: '100%'}}>
                    <Grid item>
                        <Filter/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}