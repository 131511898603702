import {useMemo} from 'react';
import {useTheme} from '@mui/material';
import {useDispatch} from '../../../../store';
import {
    blockWriter,
    privateWriter, publishVerificationWriter,
    publishWriter,
    WRITER_BLOCKED_STATUS, WRITER_DELETED_STATUS,
    WRITER_PRIVATE_STATUS,
    WRITER_PUBLIC_STATUS,
    WRITER_PUBLIC_VERIFICATION_STATUS
} from '../../../../store/slices/lawyerMedia';

export default function useStatusData(status) {

    const theme = useTheme();
    const dispatch = useDispatch();

    const STATUSES_DATA = [
        {
            status: WRITER_PRIVATE_STATUS,
            color: theme.palette.primary.main,
            text: 'Приватный',
            request: (lawyerMediaId) => dispatch(privateWriter(lawyerMediaId)),
        },
        {
            status: WRITER_PUBLIC_VERIFICATION_STATUS,
            color: theme.palette.warning.dark,
            text: 'Запрос верификации',
            request: (lawyerMediaId) => dispatch(publishVerificationWriter(lawyerMediaId)),
        },
        {
            status: WRITER_PUBLIC_STATUS,
            color: theme.palette.success.dark,
            text: 'Публичный',
            request: (lawyerMediaId) => dispatch(publishWriter(lawyerMediaId)),
        },
        {
            status: WRITER_BLOCKED_STATUS,
            color: theme.palette.error.main,
            text: 'Заблокирован',
            request: (lawyerMediaId) => dispatch(blockWriter(lawyerMediaId)),
        },
        {
            status: WRITER_DELETED_STATUS,
            color: theme.palette.grey[500],
            text: 'Будет удален',
            request: () => {},
        },
    ];

    return useMemo(() => STATUSES_DATA.find(STATUS_DATA => STATUS_DATA.status === status), [status]);
}