import ReadLess from './ReadLess';
import ReadMore from './ReadMore';

export default function Ending({open, onClick}) {

    return (
        open
            ? <ReadLess onClick={onClick}/>
            : <ReadMore onClick={onClick}/>
    );
}