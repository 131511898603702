import {Grid, TextField, useMediaQuery, useTheme} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import {gridSpacing} from '../../../store/constant';
import Currency from '../../../ui-component/Currency';
import getNumeralDeclinationWord from '../../../utils/getNumeralDeclinationWord';
import Box from '@mui/material/Box';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import completeStage from '../../../api/lawCase/completeStage';

function ActiveStage({stage}) {

    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const {id, balance, cost} = stage || {};

    const balanceView = balance ? balance / 100 : balance;

    const costView = cost ? cost / 100 : cost;

    const [loading, setLoading] = useState(false);

    const [lawyerCost, setLawyerCost] = useState(balanceView);

    const [customerCost, setCustomerCost] = useState(0);

    const onSwitchClick = e => {

        e.preventDefault();

        const newLawyerCost = customerCost;

        setCustomerCost(lawyerCost);

        setLawyerCost(newLawyerCost);
    };

    const onLayerCostClick = e => {

        e.preventDefault();

        setCustomerCost(0);

        setLawyerCost(balanceView);
    };

    const onCustomerCostClick = e => {

        e.preventDefault();

        setLawyerCost(0);

        setCustomerCost(balanceView);
    };

    const onLawyerCostChange = e => {

        const value = e.target.value;

        if (value > balanceView) {

            setLawyerCost(balanceView);

            setCustomerCost(0);

        } else if (value < 0) {

            setLawyerCost(0);

            setCustomerCost(balanceView);

        } else {

            setLawyerCost(value);

            setCustomerCost(balanceView - value);
        }
    };

    const onCustomerCostChange = e => {

        const value = e.target.value;

        if (value > balanceView) {

            setCustomerCost(balanceView);

            setLawyerCost(0);

        } else if (value < 0) {

            setCustomerCost(0);

            setLawyerCost(balanceView);

        } else {

            setCustomerCost(value);

            setLawyerCost(balanceView - value);
        }
    };

    const onClick = e => {

        e.preventDefault();

        setLoading(true);

        completeStage({stageId: id, costForCustomer: customerCost * 100, costForLawyer: lawyerCost * 100})
            .then(response => {


                console.log(response);
            })
            .catch(error => {

                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                Стоимость этапа: <b><Currency value={costView}/>&nbsp;{getNumeralDeclinationWord(costView, ['рубль', 'рубля', 'рублей'])}</b>
            </Grid>

            <Grid item xs={12}>
                Баланс &quot;Безопасной сделки&quot;: <b><Currency value={balanceView}/>&nbsp;{getNumeralDeclinationWord(balanceView, ['рубль', 'рубля', 'рублей'])}</b>
            </Grid>

            <Box sx={{width: '100%', pb: 1}} />

            <Grid item xs={12} md={5}>
                <TextField
                    size="small"
                    label="Перечислить юристу"
                    fullWidth
                    value={lawyerCost}
                    onChange={onLawyerCostChange}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Перевести всё юристу">
                                    <IconButton size="small" color="primary" onClick={onLayerCostClick} edge="end">
                                        <ArrowCircleLeftRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                    }}
                />
            </Grid>

            <Grid item xs={12} md="auto">
                <Box sx={{textAlign: 'center'}}>
                    <IconButton color="primary" size="small" onClick={onSwitchClick}>
                        {
                            matchDownMd
                                ? <ImportExportRoundedIcon />
                                : <SyncAltRoundedIcon />
                        }
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={12} md={5}>
                <TextField
                    size="small"
                    label="Перечислить заказчику"
                    fullWidth
                    value={customerCost}
                    onChange={onCustomerCostChange}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Перевести всё заказчику">
                                    <IconButton size="small" color="primary" onClick={onCustomerCostClick} edge="end">
                                        <ArrowCircleLeftRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                    }}
                />
            </Grid>

            <Box sx={{width: '100%', pb: 1}} />

            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    disableElevation
                    size="small"
                    onClick={onClick}
                    disabled={loading || balance <= 0}
                    loading={loading}
                >
                    Завершить этап
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default ActiveStage;
