import TabPanel from '@mui/lab/TabPanel';
import urls from '../../../../../utils/urls';
import Writers from '../../Writers';
import Posts from '../../Posts';
import {Box} from '@mui/material';

export default function Content() {

    return (
        <Box>
            <TabPanel value={urls.lawyerMedia} sx={{pl: 0, pr: 0, height: '100%',}}>
                <Writers/>
            </TabPanel>
            <TabPanel value={urls.lawyerMediaPosts} sx={{pl: 0, pr: 0, height: '100%',}}>
                <Posts/>
            </TabPanel>
        </Box>
    );
}