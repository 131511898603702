import {Outlet} from 'react-router-dom';
import EmptyText from '../../../ui-component/EmptyText';
import {useSelector} from '../../../store';
import {selectIsFetching, selectStages} from '../../../store/slices/stage';

function ControversialStages() {

    const controversialStages = useSelector(selectStages);
    const isFetched = useSelector(selectIsFetching);

    if (!controversialStages && !isFetched) {

        return (
            <EmptyText text="Загрузка..." />
        );
    }

    return (
        <Outlet/>
    );
}

export default ControversialStages;
