import {Box, Typography} from '@mui/material';

export default function CaptionWithText({caption, text, sx, textSx}) {

    return (
        <Box sx={sx}>
            <Typography variant="caption" component="div">{caption}</Typography>
            <Typography variant="body1" component="div">
                <Box sx={textSx}>{text}</Box>
            </Typography>
        </Box>
    );
}