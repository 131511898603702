/**
 * @return {string}
 * @return {string}
 */
export default function previewText(text = '', {maxLength = 105, ending = '...'} = {}) {

    if (!text) {

        return '';
    }

    if (text.length < maxLength) {

        return text;
    }

    const textParts = text.split(' ');
    const formattedTextParts = [];

    for (let i = 0, letterLength = 0; i < textParts.length; i++) {

        letterLength += textParts[i].length + 1;

        if (letterLength >= maxLength) {

            break;
        }

        formattedTextParts.push(textParts[i]);
    }

    const formattedText = formattedTextParts.join(' ');

    if (!/[\p{Alpha}\p{M}\p{Nd}\p{Pc}\p{Join_C}]$/igu.test(formattedText)) {

        return `${formattedText.slice(0, formattedText.length - 1)}${ending}`;
    }

    return `${formattedText}${ending}`;
}
