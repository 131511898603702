import {useState} from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from './Menu';

export default function StatusMenu({postId, scheduledDate, disableStatuses, status}) {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleClick} edge="end" size="small">
                <MoreVertIcon/>
            </IconButton>
            <Menu anchorEl={anchorEl} handleClose={handleClose} disableStatuses={disableStatuses} postId={postId} scheduledDate={scheduledDate} status={status}/>
        </>
    );
}