import {Box, Button, Grid, Typography, useTheme} from '@mui/material';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import useStatusData from '../../hooks/useStatusData';
import {WRITER_PUBLIC_STATUS, WRITER_PUBLIC_VERIFICATION_STATUS} from '../../../../../store/slices/lawyerMedia';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';

export default function PublicVerification({lawyerMediaId}) {

    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const {text} = useStatusData(WRITER_PUBLIC_VERIFICATION_STATUS);
    const {request} = useStatusData(WRITER_PUBLIC_STATUS);

    const onClick = e => {

        e.preventDefault();

        setLoading(true);

        request(lawyerMediaId)
            .catch(error => {
                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });
    };

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <Box sx={{position: 'relative',}}>
                    <ErrorOutlineRoundedIcon  sx={{color: theme.palette.error.contrastText, display: 'block'}}/>
                </Box>
            </Grid>
            <Grid item>
                <Typography component="div" color={theme.palette.error.contrastText}>{text}</Typography>
            </Grid>
            <Grid item sx={{ml: 'auto'}}>
                <LoadingButton
                    variant="contained"
                    color="invertText"
                    size="small"
                    disableElevation
                    onClick={onClick}
                    loading={loading}
                >
                    Одобрить
                </LoadingButton>
            </Grid>
        </Grid>
    );
}