import {useState} from 'react';
import MuiMenu from '@mui/material/Menu';
import useStatusData from '../../../hooks/useStatusData';
import {
    WRITER_BLOCKED_STATUS,
    WRITER_PRIVATE_STATUS,
    WRITER_PUBLIC_STATUS,
    WRITER_PUBLIC_VERIFICATION_STATUS
} from '../../../../../../store/slices/lawyerMedia';
import MenuItem from './MenuItem';


export default function Menu({anchorEl, handleClose, disableStatuses = [], lawyerMediaId}) {

    const [_loading, _setLoading] = useState(false);
    const writerPrivateStatusData = useStatusData(WRITER_PRIVATE_STATUS);
    const writerPublicStatusData = useStatusData(WRITER_PUBLIC_STATUS);
    const writerPublicVerificationStatusData = useStatusData(WRITER_PUBLIC_VERIFICATION_STATUS);
    const writerBlockedStatusData = useStatusData(WRITER_BLOCKED_STATUS);

    return (
        <MuiMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            <MenuItem
                onClick={handleClose}
                disabled={disableStatuses.includes(writerPrivateStatusData.status) || _loading}
                selected={disableStatuses.includes(writerPrivateStatusData.status) || _loading}
                text={writerPrivateStatusData.text}
                color={writerPrivateStatusData.color}
                request={writerPrivateStatusData.request}
                setLoading={_setLoading}
                lawyerMediaId={lawyerMediaId}
            />
            <MenuItem
                onClick={handleClose}
                disabled={disableStatuses.includes(writerPublicVerificationStatusData.status) || _loading}
                selected={disableStatuses.includes(writerPublicVerificationStatusData.status) || _loading}
                text={writerPublicVerificationStatusData.text}
                color={writerPublicVerificationStatusData.color}
                request={writerPublicVerificationStatusData.request}
                setLoading={_setLoading}
                lawyerMediaId={lawyerMediaId}
            />
            <MenuItem
                onClick={handleClose}
                disabled={disableStatuses.includes(writerPublicStatusData.status) || _loading}
                selected={disableStatuses.includes(writerPublicStatusData.status) || _loading}
                text={writerPublicStatusData.text}
                color={writerPublicStatusData.color}
                request={writerPublicStatusData.request}
                setLoading={_setLoading}
                lawyerMediaId={lawyerMediaId}
            />
            <MenuItem
                onClick={handleClose}
                disabled={disableStatuses.includes(writerBlockedStatusData.status) || _loading}
                selected={disableStatuses.includes(writerBlockedStatusData.status) || _loading}
                text={writerBlockedStatusData.text}
                color={writerBlockedStatusData.color}
                request={writerBlockedStatusData.request}
                setLoading={_setLoading}
                lawyerMediaId={lawyerMediaId}
            />
        </MuiMenu>
    );
}