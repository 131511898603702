import Main from './Main';
import {Route, Routes} from 'react-router-dom';
import Post from './Post';
import urls from '../../../utils/urls';

export default function LawyerMedia() {
    return (
        <Routes>
            <Route
                path=""
                element={<Main/>}
            />
            <Route
                path={`${urls.lawyerMediaPosts.replace(urls.lawyerMedia, '')}`}
                element={<Main/>}
            />
            <Route
                path={`${urls.lawyerMediaPost.replace(urls.lawyerMedia, '')}/:postId`}
                element={<Post/>}
            />
        </Routes>
    );
}