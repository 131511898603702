import {Box, Grid, useTheme} from '@mui/material';
import useStatusData from '../../../hooks/useStatusData';



export default function Status({status}) {

    const statusData = useStatusData(status);

    return (
        <Grid container alignItems="center" flexWrap="nowrap" spacing={1}>
            <Grid item>
                <Box sx={{width: '12px', height: '12px', background: statusData.color, borderRadius: '50%'}}/>
            </Grid>
            <Grid item>
                {statusData.text}
            </Grid>
        </Grid>
    );
}