import {Box, ListItemText} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import MuiMenuItem from '@mui/material/MenuItem';
import {useState} from 'react';

export default function MenuItem({postId, onClick, disabled, selected, color, text, request, setLoading}) {

    const [_loading, _setLoading] = useState(false);

    const _onClick = e => {

        e.preventDefault();

        _setLoading(true);

        setLoading(true);

        request(postId)
            .then(() => {})
            .catch(error => {
                console.log({error});
            })
            .then(() => {

                _setLoading(false);

                setLoading(false);

                onClick();
            });
    };

    return (
        <MuiMenuItem
            onClick={_onClick}
            disabled={disabled || _loading}
            selected={selected}
        >
            {
                _loading
                    ? <Box mr="8px"><CircularProgress color="grey" size={16}/></Box>
                    : <Box sx={{width: '12px', height: '12px', background: color, borderRadius: '50%', mr: '10px', my: '2px'}}/>
            }
            <ListItemText>{text}</ListItemText>
        </MuiMenuItem>
    );
}