import {Tab} from '@mui/material';
import {Link} from 'react-router-dom';
import urls from '../../../../../utils/urls';
import TabList from '@mui/lab/TabList';

export default function Tabs() {

    return (
        <TabList>
            <Tab
                label="Авторы"
                component={Link}
                to={urls.lawyerMedia}
                value={urls.lawyerMedia}
            />
            <Tab
                label="Статьи"
                component={Link}
                to={urls.lawyerMediaPosts}
                value={urls.lawyerMediaPosts}
            />
        </TabList>
    );
}