import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';

export default function Button({onClick}) {

    return (
        <IconButton onClick={onClick} edge="end" size="small">
            <MoreVertIcon/>
        </IconButton>
    );
}