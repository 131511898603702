import {useMemo} from 'react';
import getFormatRuDate from '../../../../utils/getFormatRuDate';
import CaptionWithText from './CaptionWithText';

export default function DateBlock({date, text = null, sx = {}}) {

    const dateText = useMemo(
        () => getFormatRuDate({date: new Date(date * 1000), withTime: true}),
        [date]
    );

    return (
        <CaptionWithText caption={text} text={dateText} sx={sx} textSx={{whiteSpace: 'nowrap'}}/>
    );
}