import {useState} from 'react';
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Box,
    Typography,
    useTheme,
    CardActions,
    Button, Grid
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import getFormatRuDate from '../../../../../utils/getFormatRuDate';
import AboutMe from './AboutMe';
import Bottom from './Bottom';
import Status from './Status';
import {WRITER_PUBLIC_VERIFICATION_STATUS} from '../../../../../store/slices/lawyerMedia';
import StatusMenu from './StatusMenu';
import PublicVerification from './PublicVerification';
import DateBlock from '../../common/DateBlock';
import CaptionWithText from '../../common/CaptionWithText';

export default function Writer({id, name, avatar, addDate, cover, aboutMe, status, nickname, email}) {

    const theme = useTheme();

    const anchor = `writer-${id}`;

    return (
        <Card sx={{position: 'relative', }}>
            <Box
                id={anchor}
                sx={{
                    position: 'absolute',
                    top: '-40px',
                }}
            />
            {
                status === WRITER_PUBLIC_VERIFICATION_STATUS &&
                <CardContent sx={{background: theme.palette.warning.dark}}>
                    <PublicVerification lawyerMediaId={id}/>
                </CardContent>
            }
            <CardContent sx={{pb: 0}}>
                <Grid container flexWrap="nowrap" alignItems="center">
                    <Grid item>
                        <Status status={status}/>
                    </Grid>
                    <Grid item ml="auto">
                        <StatusMenu lawyerMediaId={id} disableStatuses={[status]}/>
                    </Grid>
                </Grid>
            </CardContent>
            <CardHeader
                avatar={
                    <Avatar src={avatar} alt={name}/>
                }
                // action={<StatusMenu lawyerMediaId={id} disableStatuses={[status]}/>}
                title={name}
                // subheader={<Status status={status}/>}
            />
            {
                cover &&
                <CardMedia component="img" image={cover}/>
            }
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <AboutMe aboutMe={aboutMe} />
                    </Grid>
                    <Grid item xs={6}>
                        <DateBlock date={addDate} text="Страница создана"/>
                    </Grid>
                    <Grid item xs={6}>
                        <CaptionWithText caption="Никнейм юриста" text={nickname}/>
                    </Grid>
                    <Grid item xs={6}>
                        <CaptionWithText caption="Email юриста" text={email}/>
                    </Grid>
                </Grid>

            </CardContent>

            <CardActions sx={{pt: 0,}}>
                <Bottom id={id} lawyerNickname={nickname}/>
            </CardActions>

        </Card>
    );
}