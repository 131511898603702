import {Box} from '@mui/material';

export default function ReadMore({onClick}) {

    return (
        <Box
            component="span"
            sx={{
                display: 'inline-block',
                position: 'relative',
                cursor: 'pointer',
            }}
            onClick={onClick}
        >
            <Box
                component="span"
                sx={{
                    display: 'block',
                    position: 'absolute',
                    right: '100%',
                    width: '20px',
                    height: '20px',
                    background: 'linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%)',
                }}
            />
            <Box
                component="span"
                sx={{
                    fontWeight: 600,
                }}
            >
                Подробнее
            </Box>
        </Box>
    );
}