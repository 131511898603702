import {useTheme} from '@mui/material';
import {useDispatch} from '../../../../store';
import {
    blockPost,
    draftPost, POST_BLOCKED_STATUS,
    POST_DRAFT_STATUS,
    POST_PUBLISH_VERIFICATION_STATUS, POST_PUBLISHED_STATUS,
    POST_SCHEDULE_VERIFICATION_STATUS,
    POST_SCHEDULED_STATUS, publishPost,
    publishVerificationPost,
    schedulePost,
    scheduleVerificationPost,
} from '../../../../store/slices/lawyerMedia';
import {useMemo} from 'react';

export default function usePostStatusData(status) {

    const theme = useTheme();
    const dispatch = useDispatch();

    const STATUSES_DATA = [
        {
            status: POST_DRAFT_STATUS,
            color: theme.palette.primary.main,
            text: 'Черновик',
            request: postId => dispatch(draftPost(postId)),
        },
        {
            status: POST_PUBLISH_VERIFICATION_STATUS,
            color: theme.palette.warning.dark,
            text: 'Запрос на публикацию',
            request: postId => dispatch(publishVerificationPost(postId)),
        },
        {
            status: POST_SCHEDULE_VERIFICATION_STATUS,
            color: theme.palette.warning.dark,
            text: 'Запрос на отложенную публикацию',
            request: postId => dispatch(scheduleVerificationPost(postId)),
        },
        {
            status: POST_PUBLISHED_STATUS,
            color: theme.palette.success.dark,
            text: 'Опубликовано',
            request: postId => dispatch(publishPost(postId)),
        },
        {
            status: POST_SCHEDULED_STATUS,
            color: theme.palette.success.dark,
            text: 'Отложенная публикация',
            request: postId => dispatch(schedulePost(postId)),
        },
        {
            status: POST_BLOCKED_STATUS,
            color: theme.palette.error.main,
            text: 'Заблокировано',
            request: postId => dispatch(blockPost(postId)),
        },
    ];

    return useMemo(() => STATUSES_DATA.find(STATUS_DATA => STATUS_DATA.status === status), [status]);
}