import {useState} from 'react';
import {Link} from 'react-router-dom';
import {Box, Button} from '@mui/material';
// import ExpandMore from './ExpandMore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import {IconId, IconMessage2, IconUser, IconBriefcase, IconUserCircle, IconAlertCircle, IconBook} from '@tabler/icons';
import urls from '../../../../../../utils/urls';

const ExpandMore = styled((props) => {
    console.log({props});
    return <IconButton {...props} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
    variants: [
        {
            props: ({ expand }) => {

                console.log({expand});

                return !expand;
            },
            style: {
                transform: 'rotate(0deg)',
            },
        },
        {
            props: ({ expand }) => !!expand,
            style: {
                transform: 'rotate(180deg)',
            },
        },
    ],
}));


export default function Bottom({id, lawyerNickname}) {

    const lawyerLink = `${urls.lawyers}#lawyer-${lawyerNickname}`;
    const writerLink = `${urls.lawyerMediaPosts}?writer=${id}`;

    return (
        <>
            <Box>
                <Button
                    size="small"
                    startIcon={<IconUser stroke={1.5} size="20px"/>}
                    component={Link}
                    to={lawyerLink}
                >
                    Юрист
                </Button>
            </Box>

            <Box>
                <Button
                    size="small"
                    startIcon={<IconBook stroke={1.5} size="20px"/>}
                    component={Link}
                    to={writerLink}
                >
                    Статьи
                </Button>
            </Box>
        </>
    );
}