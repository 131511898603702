import {Box, Grid} from '@mui/material';
import usePostStatusData from '../../hooks/usePostStatusData';
import getFormatRuDate from '../../../../../utils/getFormatRuDate';

export default function Status({status, scheduledDate}) {

    const statusData = usePostStatusData(status);

    const scheduledDateText = scheduledDate
        ? <> ({getFormatRuDate({date: new Date(scheduledDate * 1000), withTime: true})})</>
        : null;

    return (
        <Grid container alignItems="center" flexWrap="nowrap" spacing={1}>
            <Grid item>
                <Box sx={{width: '12px', height: '12px', background: statusData.color, borderRadius: '50%'}}/>
            </Grid>
            <Grid item>
                {statusData.text}
                {scheduledDateText}
            </Grid>
        </Grid>
    );
}