import {Link} from 'react-router-dom';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Grid,
    Button,
    CardActions,
    useTheme
} from '@mui/material';
import {IconWritingSign} from '@tabler/icons';
import Title from './Title';
import Content from './Content';
import DateBlock from '../../common/DateBlock';
import {
    POST_PUBLISH_VERIFICATION_STATUS, POST_SCHEDULE_VERIFICATION_STATUS
} from '../../../../../store/slices/lawyerMedia';
import urls from '../../../../../utils/urls';
import CaptionWithText from '../../common/CaptionWithText';

import PublicVerification from '../../common/Post/PublicVerification';
import StatusMenu from '../../common/Post/StatusMenu';
import Status from '../../common/Post/Status';

export default function Post({
    id,
    title,
    addDate,
    status,
    image,
    content,
    scheduledDate,
    lawyerMediaId,
    lawyerMediaName
}) {

    const theme = useTheme();
    const writerLink = `${urls.lawyerMedia}?writer=${lawyerMediaId}`;

    return (
        <Card sx={{height: '100%'}}>
            <Grid container alignItems="stretch" flexWrap={{xs: 'wrap', sm: 'nowrap'}} sx={{height: '100%'}}>
                {
                    image &&
                    <Grid item xs={12} sm={5} md={4}>
                        <CardActionArea
                            sx={{height: '100%'}}
                            component={Link}
                            to={`${urls.lawyerMediaPost}/${id}`}
                        >
                            <CardMedia component="img" image={image} sx={{minHeight: '100%'}}/>
                        </CardActionArea>
                    </Grid>
                }
                <Grid item xs={12} sm={image ? 7 : 12} md={image ? 8 : 12}>
                    <Grid container flexDirection="column" sx={{height: '100%'}}>
                        <Grid item flexGrow={1} alignItems="stretch">
                            {
                                (status === POST_PUBLISH_VERIFICATION_STATUS || status === POST_SCHEDULE_VERIFICATION_STATUS) &&
                                <CardContent sx={{background: theme.palette.warning.dark}}>
                                    <PublicVerification postId={id} status={status}/>
                                </CardContent>
                            }
                            <CardContent sx={{pb: 0}}>
                                <Grid container flexWrap="nowrap" alignItems="center">
                                    <Grid item>
                                        <Status status={status} scheduledDate={scheduledDate}/>
                                    </Grid>
                                    <Grid item ml="auto">
                                        <StatusMenu postId={id} scheduledDate={scheduledDate}
                                                    disableStatuses={[status]}
                                                    status={status}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardContent>
                                <Title title={title}/>
                                <Content content={content}/>
                                <Box pt={1}/>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <DateBlock date={addDate} text="Страница создана"/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CaptionWithText caption="Автор" text={lawyerMediaName}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Grid>

                        <Grid item>
                            <CardActions sx={{pt: 0}}>
                                <Grid container spacing={2} justifyContent="space-between">
                                    <Grid item>
                                        <Button
                                            size="small"
                                            startIcon={<IconWritingSign stroke={1.5} size="20px"/>}
                                            component={Link}
                                            to={writerLink}
                                        >
                                            Автор
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            disableElevation
                                            component={Link}
                                            to={`${urls.lawyerMediaPost}/${id}`}
                                        >
                                            Подробнее
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}