import {useEffect, useState} from 'react';
import {Box, Card, CardActions, CardContent, CardMedia, Grid, Typography, useTheme} from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {gridSpacing} from '../../../../store/constant';
import urls from '../../../../utils/urls';
import fetchPost from '../../../../api/lawyerMedia/post/fetchPost';
import EmptyText from '../../../../ui-component/EmptyText';
import {
    POST_PUBLISH_VERIFICATION_STATUS,
    POST_SCHEDULE_VERIFICATION_STATUS
} from '../../../../store/slices/lawyerMedia';
import PublicVerification from '../common/Post/PublicVerification';
import Status from '../common/Post/Status';
import StatusMenu from '../common/Post/StatusMenu';
import {IconBook, IconWritingSign} from '@tabler/icons';
import Title from '../Posts/Post/Title';
import Content from '../Posts/Post/Content';
import DateBlock from '../common/DateBlock';
import CaptionWithText from '../common/CaptionWithText';


export default function Post() {

    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const [{id, title, content, image, addDate, scheduledDate, status, lawyerMediaId, lawyerMediaName}, setPost] = useState({});
    const writerLink = `${urls.lawyerMedia}?writer=${lawyerMediaId}`;
    const writerPostsLink = `${urls.lawyerMediaPosts}?writer=${lawyerMediaId}`;
    const {postId} = useParams();

    useEffect(() => {

        setLoading(true);

        fetchPost(postId)
            .then(post => {

                setPost(post);
            })
            .catch(error => {

                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });

    }, [postId]);

    return (
        <Grid container spacing={gridSpacing} alignItems="stretch">
            <Grid item xs={12}>
                <Box sx={{py: '6px'}}>
                    <Button
                        component={Link}
                        to={urls.lawyerMediaPosts}
                        variant="text"
                        startIcon={<ArrowBackIosNewRoundedIcon/>}
                    >
                        Назад
                    </Button>
                </Box>
            </Grid>
            {
                loading
                    ? (
                        <Grid xs={12} item>
                            <EmptyText text="Загрузка..."/>
                        </Grid>
                    )
                    : (
                        <>
                            <Grid item xs={12}>
                                <Box sx={{maxWidth: '717px', mx: 'auto'}}>
                                    <Card>
                                        {
                                            (status === POST_PUBLISH_VERIFICATION_STATUS || status === POST_SCHEDULE_VERIFICATION_STATUS) &&
                                            <CardContent sx={{background: theme.palette.warning.dark}}>
                                                <PublicVerification postId={id} status={status}/>
                                            </CardContent>
                                        }
                                        <CardContent sx={{pb: 0}}>
                                            <Grid container flexWrap="nowrap" alignItems="center">
                                                <Grid item>
                                                    <Status status={status} scheduledDate={scheduledDate}/>
                                                </Grid>
                                                <Grid item ml="auto">
                                                    <StatusMenu postId={id} scheduledDate={scheduledDate} disableStatuses={[status]} status={status}/>
                                                </Grid>
                                            </Grid>
                                        </CardContent>

                                        <CardContent>
                                            <Grid container spacing={gridSpacing}>
                                                <Grid item >
                                                    <DateBlock date={addDate} text="Страница создана"/>
                                                </Grid>
                                                <Grid item>
                                                    <CaptionWithText caption="Автор" text={lawyerMediaName}/>
                                                </Grid>
                                            </Grid>
                                        </CardContent>

                                        <CardActions sx={{pt: 0}}>
                                            <Grid container spacing={2}>
                                                <Grid item>
                                                    <Button
                                                        size="small"
                                                        startIcon={<IconWritingSign stroke={1.5} size="20px"/>}
                                                        component={Link}
                                                        to={writerLink}
                                                    >
                                                        Автор
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        size="small"
                                                        startIcon={<IconBook stroke={1.5} size="20px"/>}
                                                        component={Link}
                                                        to={writerPostsLink}
                                                    >
                                                        Статьи автора
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{maxWidth: '717px', mx: 'auto'}}>
                                    <Card>
                                        {
                                            image &&
                                            <CardMedia component="img" image={image}/>
                                        }
                                        <CardContent>
                                            {
                                                title &&
                                                <Typography variant="h1">{title}</Typography>
                                            }
                                            {
                                                content &&
                                                <Box
                                                    sx={{
                                                        '&': {
                                                            outline: '0 none',
                                                            fontSize: '18px',
                                                            lineHeight: 1.4,
                                                            color: theme.palette.grey[600],
                                                            '.ProseMirror-selectednode': {
                                                                outline: `3px solid ${theme.palette.primary.main}`
                                                            },
                                                            'blockquote': {
                                                                borderLeft: `3px solid ${theme.palette.grey[500]}`,
                                                                pl: '1rem',
                                                                ml: 0
                                                            },
                                                            a: {
                                                                color: theme.palette.primary.main,
                                                                textDecoration: 'underline',
                                                            },
                                                        },
                                                        'p.is-editor-empty:first-child:before': {
                                                            color: theme.palette.text.disabled,
                                                            content: 'attr(data-placeholder)',
                                                            float: 'left',
                                                            height: 0,
                                                            pointerEvents: 'none'
                                                        },
                                                    }}
                                                    dangerouslySetInnerHTML={{ __html: content }}
                                                />
                                            }
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </>
                    )
            }
        </Grid>
    );
}