import {useState} from 'react';
import Button from './Button';
import Menu from './Menu';

export default function StatusMenu({lawyerMediaId, disableStatuses}) {

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button onClick={handleClick}/>
            <Menu anchorEl={anchorEl} handleClose={handleClose} disableStatuses={disableStatuses} lawyerMediaId={lawyerMediaId}/>
        </>
    );
}