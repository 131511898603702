import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import fetchWritersHttp from '../../api/lawyerMedia/writer/fetchList';
import fetchWriterHttp from '../../api/lawyerMedia/writer/fetch';
import privateWriterHttp from '../../api/lawyerMedia/writer/private';
import publishWriterHttp from '../../api/lawyerMedia/writer/publish';
import publishVerificationWriterHttp from '../../api/lawyerMedia/writer/publishVerification';
import blockWriterHttp from '../../api/lawyerMedia/writer/block';
import fetchPostsHttp from '../../api/lawyerMedia/post/fetchPosts';
import fetchPostHttp from '../../api/lawyerMedia/post/fetchPost';
import setStatusHttp from '../../api/lawyerMedia/post/setStatus';

export const WRITER_PRIVATE_STATUS = 'PRIVATE_STATUS';
export const WRITER_PUBLIC_STATUS = 'PUBLIC_STATUS';
export const WRITER_PUBLIC_VERIFICATION_STATUS = 'PUBLIC_VERIFICATION_STATUS';
export const WRITER_BLOCKED_STATUS = 'BLOCKED_STATUS';
export const WRITER_DELETED_STATUS = 'DELETED_STATUS';


export const POST_DRAFT_STATUS = 'DRAFT_STATUS';
export const POST_PUBLISH_VERIFICATION_STATUS = 'PUBLISH_VERIFICATION_STATUS';
export const POST_SCHEDULE_VERIFICATION_STATUS = 'SCHEDULE_VERIFICATION_STATUS';
export const POST_SCHEDULED_STATUS = 'SCHEDULED_STATUS';
export const POST_PUBLISHED_STATUS = 'PUBLISHED_STATUS';
export const POST_BLOCKED_STATUS = 'BLOCKED_STATUS';

export const fetchWriters = createAsyncThunk('lawyerMedia/writers', async ({lawyerMediaId}) => {

    if (lawyerMediaId) {

        const response = await fetchWriterHttp(lawyerMediaId)

        return [response.lawyerMedia];
    }

    const response = await fetchWritersHttp();

    return response.lawyerMedia;
});

export const privateWriter = createAsyncThunk('lawyerMedia/writer/private', async (lawyerMediaId) => {

    const writer = await privateWriterHttp(lawyerMediaId);

    return writer.lawyerMedia;
});

export const publishWriter = createAsyncThunk('lawyerMedia/writer/publish', async (lawyerMediaId) => {

    const writer = await publishWriterHttp(lawyerMediaId);

    return writer.lawyerMedia;
});

export const publishVerificationWriter = createAsyncThunk('lawyerMedia/writer/publishVerification', async (lawyerMediaId) => {

    const writer = await publishVerificationWriterHttp(lawyerMediaId);

    return writer.lawyerMedia;
});

export const blockWriter = createAsyncThunk('lawyerMedia/writer/block', async (lawyerMediaId) => {

    const writer = await blockWriterHttp(lawyerMediaId);

    return writer.lawyerMedia;
});


export const fetchPosts = createAsyncThunk('lawyerMedia/posts', async ({lawyerMediaId}) => {

    const posts = await fetchPostsHttp({lawyerMediaId});

    return posts.posts;
});

export const fetchPost = createAsyncThunk('lawyerMedia/post', async ({postId}) => {

    const posts = await fetchPostHttp({postId});

    return posts.post;
});

export const publishVerificationPost = createAsyncThunk('lawyerMedia/post/publishVerification', async (postId) => {

    const post = await setStatusHttp({postId, status: POST_PUBLISH_VERIFICATION_STATUS});

    return post.post;
});

export const scheduleVerificationPost = createAsyncThunk('lawyerMedia/post/scheduleVerification', async (postId) => {

    const post = await setStatusHttp({postId, status: POST_SCHEDULE_VERIFICATION_STATUS});

    return post.post;
});

export const publishPost = createAsyncThunk('lawyerMedia/post/publish', async (postId) => {

    const post = await setStatusHttp({postId, status: POST_PUBLISHED_STATUS});

    return post.post;
});

export const schedulePost = createAsyncThunk('lawyerMedia/post/schedule', async (postId) => {

    const post = await setStatusHttp({postId, status: POST_SCHEDULED_STATUS});

    return post.post;
});

export const draftPost = createAsyncThunk('lawyerMedia/post/draft', async (postId) => {

    const post = await setStatusHttp({postId, status: POST_DRAFT_STATUS});

    return post.post;
});

export const blockPost = createAsyncThunk('lawyerMedia/post/block', async (postId) => {

    const post = await setStatusHttp({postId, status: POST_BLOCKED_STATUS});

    return post.post;
});

const initialState = {
    writers: [],
    writersUpdating: true,
    posts: [],
    postsUpdating: true,
};

const onPostPending = (posts, postId) => posts.map(post => ({...post, updating: postId === post.id}));
const onPostFulfilled = (posts, payload) => posts.map(post => (
    post.id === payload.id
        ? {...post, ...payload, updating: false}
        : post
));
const onPostRejected = (posts, payload) => posts.map(post => ({...post, updating: post.id === payload.id ? false : post.updating}));

const slice = createSlice({
    name: 'lawyerMedia',
    initialState,
    reducers: {
        setWriters(state, {payload}) {state.writers = payload;},
        setPosts(state, {payload}) {state.posts = payload;},
    },
    extraReducers(builder) {
        builder
            .addCase(fetchWriters.pending, (state, {meta: {arg: {withoutUpdating} = {withoutUpdating: false}}}) => {

                if (!withoutUpdating) {

                    state.writersUpdating = true;
                }
            })
            .addCase(fetchWriters.fulfilled, (state, {payload, meta: {arg: {replace}}}) => {

                state.writersUpdating = false;

                state.writers = replace
                    ? (payload || [])
                    : (payload || []).map(writer => ({...writer, updating: false}));
            })
            .addCase(fetchWriters.rejected, (state, {meta: {arg: {withoutUpdating}}} = {meta: {arg: {withoutUpdating: false}}}) => {

                if (!withoutUpdating) {
                    state.writersUpdating = true;
                }
            })


            .addCase(privateWriter.pending, (state, {meta: {arg: lawyerMediaId}}) => {

                state.writers = state.writers.map(writer => ({...writer, updating: lawyerMediaId === writer.id}));
            })
            .addCase(privateWriter.fulfilled, (state, {payload}) => {

                state.writers = state.writers.map(writer => (
                    writer.id === payload.id
                        ? {...writer, ...payload, updating: false}
                        : writer
                ));
            })
            .addCase(privateWriter.rejected, (state, {payload}) => {

                state.writers = state.writers.map(writer => ({...writer, updating: writer.id === payload.id ? false : writer.updating}));
            })


            .addCase(publishWriter.pending, (state, {meta: {arg: lawyerMediaId}}) => {
                console.log(1);
                state.writers = state.writers.map(writer => ({...writer, updating: lawyerMediaId === writer.id}));
            })
            .addCase(publishWriter.fulfilled, (state, {payload}) => {
                console.log(2);
                state.writers = state.writers.map(writer => (
                    writer.id === payload.id
                        ? {...writer, ...payload, updating: false}
                        : writer
                ));
            })
            .addCase(publishWriter.rejected, (state, {payload}) => {
                console.log(3);
                state.writers = state.writers.map(writer => ({...writer, updating: writer.id === payload.id ? false : writer.updating}));
            })


            .addCase(publishVerificationWriter.pending, (state, {meta: {arg: lawyerMediaId}}) => {

                state.writers = state.writers.map(writer => ({...writer, updating: lawyerMediaId === writer.id}));
            })
            .addCase(publishVerificationWriter.fulfilled, (state, {payload}) => {

                state.writers = state.writers.map(writer => (
                    writer.id === payload.id
                        ? {...writer, ...payload, updating: false}
                        : writer
                ));
            })
            .addCase(publishVerificationWriter.rejected, (state, {payload}) => {

                state.writers = state.writers.map(writer => ({...writer, updating: writer.id === payload.id ? false : writer.updating}));
            })


            .addCase(blockWriter.pending, (state, {meta: {arg: lawyerMediaId}}) => {

                state.writers = state.writers.map(writer => ({...writer, updating: lawyerMediaId === writer.id}));
            })
            .addCase(blockWriter.fulfilled, (state, {payload}) => {

                state.writers = state.writers.map(writer => (
                    writer.id === payload.id
                        ? {...writer, ...payload, updating: false}
                        : writer
                ));
            })
            .addCase(blockWriter.rejected, (state, {payload}) => {

                state.writers = state.writers.map(writer => ({...writer, updating: writer.id === payload.id ? false : writer.updating}));
            })


            .addCase(fetchPosts.pending, (state, {meta: {arg: {withoutUpdating} = {withoutUpdating: false}}}) => {

                if (!withoutUpdating) {
                    state.postsUpdating = true;
                }
            })
            .addCase(fetchPosts.fulfilled, (state, {payload, meta: {arg: {replace}}}) => {

                console.log({replace});

                state.postsUpdating = false;

                state.posts = replace
                    ? (payload || [])
                    : (payload || []).map(posts => ({...posts, updating: false}));
            })
            .addCase(fetchPosts.rejected, (state, {meta: {arg: {withoutUpdating} = {withoutUpdating: false}}}) => {

                if (!withoutUpdating) {
                    state.postsUpdating = true;
                }
            })


            .addCase(fetchPost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(fetchPost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(fetchPost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })


            .addCase(publishVerificationPost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(publishVerificationPost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(publishVerificationPost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })


            .addCase(scheduleVerificationPost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(scheduleVerificationPost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(scheduleVerificationPost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })


            .addCase(publishPost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(publishPost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(publishPost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })


            .addCase(schedulePost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(schedulePost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(schedulePost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })


            .addCase(draftPost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(draftPost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(draftPost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })


            .addCase(blockPost.pending, (state, {meta: {arg: postId}}) => {

                state.posts = onPostPending(state.posts, postId);
            })
            .addCase(blockPost.fulfilled, (state, {payload}) => {

                state.posts = onPostFulfilled(state.posts, payload);
            })
            .addCase(blockPost.rejected, (state, {payload}) => {

                state.posts = onPostRejected(state.posts, payload);
            })
    },
});

export default slice.reducer;

export const {
    setWriters,
    setPosts,
} = slice.actions;

export const selectWriters = ({lawyerMedia: {writers}}) => writers;
export const selectWritersUpdating = ({lawyerMedia: {writersUpdating}}) => writersUpdating;
export const selectPosts = ({lawyerMedia: {posts}}) => posts;
export const selectPostsUpdating = ({lawyerMedia: {postsUpdating}}) => postsUpdating;