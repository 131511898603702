import {useEffect, useState} from 'react';
import {fetchPosts, fetchWriters} from '../../store/slices/lawyerMedia';
import {useDispatch} from '../../store';
import useAuth from '../../hooks/useAuth';
import {useSearchParams} from 'react-router-dom';

const FETCH_INTERVAL = 30000;

export default function LawyerMediaProvider({children}) {

    const dispatch = useDispatch();
    const {isInitialized, isLoggedIn} = useAuth();
    const [fetchCount, setFetchCount] = useState(0);
    const [searchParams] = useSearchParams();
    const writer = searchParams.get('writer');

    const fetch = withoutUpdating => {

        dispatch(fetchWriters({withoutUpdating, lawyerMediaId: writer, replace: !!writer}));

        dispatch(fetchPosts({withoutUpdating, lawyerMediaId: writer, replace: !!writer}));
    };

    useEffect(() => {

        if (isInitialized && isLoggedIn) {

            fetch();
        }

    }, [isInitialized, isLoggedIn]);

    useEffect(() => {

        let interval = null;

        if (isInitialized && isLoggedIn) {

            interval = setInterval(
                () => {

                    fetch(true);

                    setFetchCount(fetchCount => fetchCount + 1);
                },
                FETCH_INTERVAL
            );
        }

        return () => {

            clearInterval(interval);
        };

    }, [isInitialized, isLoggedIn, fetchCount, writer]);

    return children;
}