import {Link, useLocation} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {gridSpacing} from '../../../../store/constant';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import urls from '../../../../utils/urls';

function Tabs() {

    return (
        <Grid
            container
            spacing={gridSpacing}
            alignItems="stretch"
        >
            <Grid
                item
                xs={12}
            >
                <TabList>
                    <Tab
                        key={urls.controversialChats}
                        label="Активные"
                        component={Link}
                        to={urls.controversialChats}
                        value={urls.controversialChats}
                    />
                    <Tab
                        key={urls.controversialChatsNew}
                        label="Новые"
                        component={Link}
                        to={urls.controversialChatsNew}
                        value={urls.controversialChatsNew}
                    />
                </TabList>
            </Grid>
        </Grid>

    );
}

export default Tabs;
